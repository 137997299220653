import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/components/ui-components';

import { useLocalization } from 'src/contexts';
import type { CashFormProps } from './cash-form.props';

import './cash-form.scss';

const CashForm = observer(
  ({ cashCheckStore, isVisible, loadingPayment }: CashFormProps) => {
    const { getLocalization } = useLocalization();
    const { isCheckOptionAgreed, toggleCheckOption } = cashCheckStore;

    return (
      <div
        className={classNames('cash-form', !isVisible && 'cash-form_hidden')}
      >
        <div className="cash-form__wrapper">
          <p className="cash-form__text">
            {getLocalization('Pay directly to the cemetery')}
          </p>
        </div>
        <Checkbox
          className="cash-form__checkbox"
          id="paymentPageCashCheckbox"
          isChecked={isCheckOptionAgreed}
          isDisabled={loadingPayment}
          label={getLocalization(
            'I will pay by cash or bank check at the cemetery',
          )}
          onChange={toggleCheckOption}
        />
      </div>
    );
  },
);

export default CashForm;
