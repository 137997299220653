import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import dayjs from 'dayjs';

import {
  ALLOWED_HTML_CHARS_CODE,
  CURRENT_LOCALE,
  IS_WITH_LOCALIZATION,
} from 'src/constants';
import type { TLocale } from 'src/interfaces';
import type {
  LocalizationContextProps,
  LocalizationProviderProps,
} from './localization-context.props';

interface ILocalization {
  [key: string]: string;
}

const LocalizationContext = createContext<LocalizationContextProps>({
  isLocalizationLoading: false,
  locale: CURRENT_LOCALE,
  getLocalization: (text: string) => text,
  setLocale: (_locale: TLocale) => {},
});

const filterStringValues = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce<Record<string, string>>((result, key) => {
    result[key] = obj[key];
    return result;
  }, {});
};

export const LocalizationProvider = ({
  children,
}: LocalizationProviderProps) => {
  const [isLocalizationLoading, setIsLocalizationLoading] =
    useState<boolean>(false);
  const [locale, setLocale] = useState<TLocale>(CURRENT_LOCALE);
  const [localization, setLocalization] = useState<ILocalization>({});

  useEffect(() => {
    const fetchLocalizationData = async () => {
      setIsLocalizationLoading(true);

      await import(`dayjs/locale/${locale}.js`)
        .then(() => dayjs.locale(locale))
        .catch(console.error);

      try {
        const response = await axios.get<ILocalization>(
          `/localization/${locale}.json`,
        );
        setLocalization(filterStringValues(response.data));
      } catch (e) {
        console.log(e);
        setLocalization({});
      } finally {
        setIsLocalizationLoading(false);
      }
    };

    if (IS_WITH_LOCALIZATION) {
      void fetchLocalizationData();
    }
  }, [locale]);

  const getLocalization = useCallback(
    (text: string): string => {
      let translatedText = text;

      if (Object.keys(localization).length) {
        const lowerCaseText = text.toLowerCase();
        const translatedKey = Object.keys(localization).find(
          (k) => k.toLowerCase() === lowerCaseText,
        );

        if (translatedKey && localization[translatedKey]) {
          translatedText = localization[translatedKey];
        }
      }

      return translatedText.replace(
        new RegExp(Object.keys(ALLOWED_HTML_CHARS_CODE).join('|'), 'g'),
        (str) =>
          String.fromCharCode(
            ALLOWED_HTML_CHARS_CODE[
              str as keyof typeof ALLOWED_HTML_CHARS_CODE
            ],
          ),
      );
    },
    [localization],
  );

  return (
    <LocalizationContext.Provider
      value={{ isLocalizationLoading, locale, getLocalization, setLocale }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
