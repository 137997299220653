import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Button } from 'src/components/ui-components';

import { useLocalization } from 'src/contexts';
import type { PaymentFormActionsProps } from './payment-form-actions.props';

import './payment-form-actions.scss';

const PaymentFormActions = observer(
  ({ formStore }: PaymentFormActionsProps) => {
    const history = useHistory();
    const { getLocalization } = useLocalization();
    const { isPaymentFormInvalid, isPayPalMethod, handlePaymentButtonClick } =
      formStore;

    return (
      <div
        className={classNames(
          'payment-form-actions',
          isPayPalMethod && 'payment-form-actions_paypal',
        )}
      >
        <Button
          className="payment-form-actions__button payment-form-actions__button_cancel"
          label={getLocalization('Cancel')}
          size="oversize"
          theme="outlined"
          onClick={history.goBack}
        />
        {!isPayPalMethod && (
          <Button
            className="payment-form-actions__button payment-form-actions__button_payment"
            isDisabled={isPaymentFormInvalid}
            label={getLocalization('Make a Payment')}
            size="oversize"
            theme="filled"
            onClick={handlePaymentButtonClick}
          />
        )}
      </div>
    );
  },
);

export default PaymentFormActions;
