import React from 'react';

import {
  AFSLogo,
  ExternalLink,
  Footer,
  FooterLinks,
} from 'src/components/common-components';
import Logo from 'src/components/common-components/logo';
import LoginForm from './components/login-form';

import { LogoTextLocation } from 'src/constants';
import { useLocalization } from 'src/contexts';

import './login-page.scss';

const LoginPage = () => {
  const { getLocalization } = useLocalization();

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <div className="login-page__background-wrapper">
          <Logo
            className="login-page__logo-mobile"
            textLocation={LogoTextLocation.BOTTOM}
          />

          <picture>
            <source
              media="(max-width: 640px)"
              srcSet="/main-bg/family-portal/mobile.jpg"
            />
            <source
              media="(max-width: 1440px)"
              srcSet="/main-bg/family-portal/tablet.jpg"
            />
            <source
              media="(max-width: 1920px)"
              srcSet="/main-bg/family-portal/desktop.jpg"
            />
            <source
              media="(max-width: 2560px)"
              srcSet="/main-bg/family-portal/fullhd.jpg"
            />
            <img
              className="login-page__background-image"
              alt={getLocalization('Cemetery view')}
              src="/main-bg/family-portal/2k.jpg"
            />
          </picture>
          <ExternalLink
            className="login-page__footer-logo"
            href="https://allfuneral.com/"
            title="All Funeral Services"
          >
            <AFSLogo className="login-page__footer-logo-afs" page="login" />
          </ExternalLink>
        </div>
        <div className="login-page__form-wrapper">
          <Logo
            className="login-page__logo"
            isColored
            textLocation={LogoTextLocation.BOTTOM}
          />
          <LoginForm className="login-page__form" />

          <FooterLinks className="login-page__links" page="login" />
        </div>
      </div>
      <Footer className="login-page__footer" />
    </div>
  );
};

export default LoginPage;
