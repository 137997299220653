import React from 'react';
import classNames from 'classnames';

import type { RadioboxProps } from './radiobox.props';

import './radiobox.scss';

function Radiobox({
  id,
  isChecked,
  isDisabled,
  label,
  name,
  onChange,
}: RadioboxProps) {
  const inputId = `${name}-${id}`;
  return (
    <div className={classNames('radiobox', isDisabled && 'radiobox_disabled')}>
      <input
        className="radiobox__input"
        id={inputId}
        checked={isChecked}
        disabled={isDisabled}
        name={name}
        type="radio"
        onChange={() => onChange(id)}
      />
      <label className="radiobox__label" htmlFor={inputId}>
        <span className="radiobox__icon" />
        {label}
      </label>
    </div>
  );
}

export default Radiobox;
