export const LOCALE_LIST = Array.isArray(window.LOCALES) ? window.LOCALES : [];

export const IS_WITH_LOCALIZATION = LOCALE_LIST.length;

export const DEFAULT_LOCALE = 'en';

export const CURRENT_LOCALE = IS_WITH_LOCALIZATION
  ? LOCALE_LIST[0]
  : DEFAULT_LOCALE;

export const ALLOWED_HTML_CHARS_CODE = {
  '&amp;': 38,
  '&apos;': 39,
  '&laquo;': 171,
  '&raquo;': 187,
  '&ldquo;': 8220,
  '&rdquo;': 8221,
};
