import * as Square from '@square/web-sdk';
import { makeAutoObservable } from 'mobx';

class SquareStore {
  applicationId = window.PAYMENT_SETTINGS?.card?.squareApplicationId || '';
  locationId = window.PAYMENT_SETTINGS?.card?.squareLocationId || '';
  paymentsInstance: Square.Payments | undefined;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPaymentsInstance(instance: Square.Payments) {
    this.paymentsInstance = instance;
  }

  async loadSquarePayments() {
    if (this.paymentsInstance) {
      return;
    }

    try {
      const payments = await Square.payments(
        this.applicationId,
        this.locationId,
      );
      if (payments) {
        this.setPaymentsInstance(payments);
        return payments;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new SquareStore();
