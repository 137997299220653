import React from 'react';
import classNames from 'classnames';

import { DataList, DataListPaired } from 'src/components/common-components';

import { useLocalization } from 'src/contexts';
import {
  getCemeteryData,
  getFormattedServices,
  getLotData,
  getOrderAdditionalLabel,
  getPaymentData,
  getPersonalData,
  getServices,
  getTotal,
} from 'src/utils';
import type { IDataListPair, IPerson } from 'src/interfaces';
import type { OrderSummaryProps } from './order-summary.props';

import './order-summary.scss';

const OrderSummary = ({ className, order }: OrderSummaryProps) => {
  const { getLocalization } = useLocalization();
  const {
    cemetery,
    deedOwners,
    inventoryLot,
    lots,
    payment,
    purchasers,
    services,
  } = order;
  const cemeteryDataList = getCemeteryData(cemetery, getLocalization);
  const lotsDataList = inventoryLot
    ? [getLotData(inventoryLot, getLocalization)]
    : lots?.map((lot) => getLotData(lot, getLocalization));
  const deedOwnersList =
    deedOwners?.map((owner: IPerson) =>
      getPersonalData(owner, getLocalization),
    ) || [];
  const purchaserDataList =
    purchasers?.map((purchaser: IPerson) =>
      getPersonalData(purchaser, getLocalization),
    ) || [];
  const paymentDataList = getPaymentData(payment, getLocalization);
  const servicesList = getServices(
    getFormattedServices(services, getLocalization),
    getLocalization,
  );
  const totalList = getTotal(
    payment.total,
    payment.goodsAndServicesTax,
    payment.perpetualCare,
    payment.discount?.value,
    getLocalization,
  );
  const additionalInfo = getOrderAdditionalLabel(order);

  return (
    <div className={classNames('order-summary', className)}>
      <DataListPaired
        className="data-list_cemetery"
        id="cemeteryDataList"
        pairs={cemeteryDataList}
        title={getLocalization('Cemetery')}
      />
      <div className="data-list__putted-list">
        <h2 className="data-list__putted-list-title">
          {getLocalization('Purchase')}
        </h2>
        {additionalInfo && (
          <DataList
            className="data-list_empty"
            title={getLocalization(additionalInfo)}
          >
            {''}
          </DataList>
        )}{' '}
        {(!!inventoryLot || !!lots?.length) && (
          <DataListPaired
            className="data-list_lots"
            id="lotDataList"
            pairsList={lotsDataList}
            title={getLocalization('Property')}
          />
        )}
        {servicesList?.length > 0 && (
          <DataListPaired
            id="servicesDataList"
            pairsList={servicesList}
            title={getLocalization('Services')}
          />
        )}
        <DataListPaired
          className="data-list_total"
          id="totalList"
          pairs={totalList}
        />
      </div>
      {deedOwnersList.map((owner: IDataListPair[], idx: number) => (
        <DataListPaired
          id={`deedOwnerDataList-${idx}`}
          key={`deedOwnerDataList-${idx}`}
          pairs={owner}
          title={
            deedOwnersList.length > 1
              ? `${getLocalization('Rights Holder')} ${idx + 1}`
              : getLocalization('Rights Holder')
          }
        />
      ))}
      {purchaserDataList.map((purchaser: IDataListPair[], idx: number) => (
        <DataListPaired
          id={`purchaserDataList-${idx}`}
          key={`purchaserDataList-${idx}`}
          pairs={purchaser}
          title={
            purchaserDataList.length > 1
              ? `${getLocalization('Purchaser')} ${idx + 1}`
              : getLocalization('Purchaser')
          }
        />
      ))}
      <DataListPaired
        className="data-list_plan"
        id="paymentDataList"
        pairs={paymentDataList}
        title={getLocalization('Payment Plan')}
      />
    </div>
  );
};

export default OrderSummary;
