import React from 'react';
import classNames from 'classnames';

import { Input } from 'src/components/ui-components';

import { LabelTheme } from 'src/constants';
import { useLocalization } from 'src/contexts';
import type { BillingAddressProps } from './billing-address.props';

import './billing-address.scss';

const BillingAddress = ({
  className,
  address,
  handleBillingAddressChange,
  isDisabled,
}: BillingAddressProps) => {
  const { getLocalization } = useLocalization();
  const { building, street, room, city, state, zip } = address;

  const handleInputChange = (addressPart: string, value: string) => {
    const newAddress = { ...address, [addressPart]: value };
    handleBillingAddressChange(newAddress);
  };

  return (
    <div className={classNames('billing-address', className)}>
      <Input
        className="billing-address__input"
        id="billingAddressBuilding"
        isDisabled={isDisabled}
        label={getLocalization('Building')}
        labelColor={LabelTheme.LIGHT}
        value={building || ''}
        onChange={(val) => handleInputChange('building', val)}
      />
      <Input
        className="billing-address__input"
        id="billingAddressStreet"
        isDisabled={isDisabled}
        label={getLocalization('Street')}
        labelColor={LabelTheme.LIGHT}
        value={street || ''}
        onChange={(val) => handleInputChange('street', val)}
      />
      <Input
        className="billing-address__input"
        id="billingAddressApt"
        isDisabled={isDisabled}
        label={getLocalization('Apt/Room/Office')}
        labelColor={LabelTheme.LIGHT}
        value={room || ''}
        onChange={(val) => handleInputChange('room', val)}
      />
      <Input
        className="billing-address__input"
        id="billingAddressCity"
        isDisabled={isDisabled}
        label={getLocalization('City')}
        labelColor={LabelTheme.LIGHT}
        value={city || ''}
        onChange={(val) => handleInputChange('city', val)}
      />
      <Input
        className="billing-address__input"
        id="billingAddressState"
        isDisabled={isDisabled}
        label={getLocalization('State')}
        labelColor={LabelTheme.LIGHT}
        value={state || ''}
        onChange={(val) => handleInputChange('state', val)}
      />
      <Input
        className="billing-address__input"
        id="billingAddressZip"
        isDisabled={isDisabled}
        label={getLocalization('Zip')}
        labelColor={LabelTheme.LIGHT}
        value={zip || ''}
        onChange={(val) => handleInputChange('zip', val)}
      />
    </div>
  );
};

export default BillingAddress;
