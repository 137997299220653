import type { IPaymentMethodOption } from 'src/interfaces';

const { PAYMENT_SETTINGS = {} } = window;

const AVAILABLE_CONFIG_PAYMENT_METHODS =
  typeof PAYMENT_SETTINGS === 'object' ? Object.keys(PAYMENT_SETTINGS) : [];

export const PAYPAL_CLIENT_ID = PAYMENT_SETTINGS.paypal?.paypalClientId || '';

export const CARD_PAYMENT_VENDOR_NAME = PAYMENT_SETTINGS.card?.vendor || '';

export const PaymentMethodApi = {
  BANK_TRANSFER: 'bank_transfer',
  CASH: 'cash',
  CHECK: 'check',
  DEBIT_CREDIT_CARD: 'credit_card',
  INSURANCE: 'insurance',
  PAYPAL: 'paypal',
};

export const PaymentMethod = {
  [PaymentMethodApi.BANK_TRANSFER]: 'Bank Transfer',
  [PaymentMethodApi.CASH]: 'Cash',
  [PaymentMethodApi.CHECK]: 'Check',
  [PaymentMethodApi.DEBIT_CREDIT_CARD]: 'Bank Card',
  [PaymentMethodApi.INSURANCE]: 'Insurance',
  CASH_CHECK: 'Cash/Check',
  PAYPAL: 'PayPal',
};

export const isCardPaymentVendorEligible: boolean =
  (CARD_PAYMENT_VENDOR_NAME === 'stripe' &&
    !!PAYMENT_SETTINGS.card?.stripePublicKey) ||
  (CARD_PAYMENT_VENDOR_NAME === 'square' &&
    !!PAYMENT_SETTINGS.card?.squareApplicationId &&
    !!PAYMENT_SETTINGS.card?.squareLocationId) ||
  CARD_PAYMENT_VENDOR_NAME === 'optionPay';

export const cardPaymentVendorProps = isCardPaymentVendorEligible
  ? PAYMENT_SETTINGS.card
  : null;

export const isBankTransferPaymentVendorEligible: boolean =
  PAYMENT_SETTINGS.bankTransfer?.vendor === 'stripe' &&
  !!PAYMENT_SETTINGS.bankTransfer?.stripePublicKey;

export const isCardMethodAvailable =
  isCardPaymentVendorEligible &&
  AVAILABLE_CONFIG_PAYMENT_METHODS.includes('card');

export const isBankTransferMethodAvailable =
  isBankTransferPaymentVendorEligible &&
  AVAILABLE_CONFIG_PAYMENT_METHODS.includes('bankTransfer');

export const isPayPalMethodAvailable =
  !!PAYPAL_CLIENT_ID && AVAILABLE_CONFIG_PAYMENT_METHODS.includes('paypal');

export const cardPaymentOption: IPaymentMethodOption = {
  id: 'card',
  value: PaymentMethod[PaymentMethodApi.DEBIT_CREDIT_CARD],
  apiValue: PaymentMethodApi.DEBIT_CREDIT_CARD,
};

export const bankTransferPaymentOption: IPaymentMethodOption = {
  id: 'bankTransfer',
  value: PaymentMethod[PaymentMethodApi.BANK_TRANSFER],
  apiValue: PaymentMethodApi.BANK_TRANSFER,
};

export const paypalPaymentOption: IPaymentMethodOption = {
  id: 'paypal',
  value: PaymentMethod.PAYPAL,
  apiValue: PaymentMethodApi.PAYPAL,
};

export const cashPaymentOption: IPaymentMethodOption = {
  id: 'cash',
  value: PaymentMethod.CASH_CHECK,
  apiValue: PaymentMethodApi.CASH,
};

export const paymentMethodOptions = AVAILABLE_CONFIG_PAYMENT_METHODS.map(
  (method) => {
    switch (method) {
      case 'card':
        return isCardPaymentVendorEligible ? cardPaymentOption : undefined;
      case 'bankTransfer':
        return isBankTransferPaymentVendorEligible
          ? bankTransferPaymentOption
          : undefined;
      case 'paypal':
        return !!PAYPAL_CLIENT_ID ? paypalPaymentOption : undefined;
      case 'cash':
        return cashPaymentOption;
      default:
        return undefined;
    }
  },
).filter(Boolean);
