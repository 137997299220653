import { makeAutoObservable } from 'mobx';

import api from 'src/api';
import { ApiStatus, PaymentMethodApi, PaymentStatusApi } from 'src/constants';

class OptionPayCardStore {
  isSubmitting: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  async sendCardPaymentRequest(
    amount: number,
    paymentId: string,
    onError: (err?: string) => void,
  ) {
    if (this.isSubmitting) {
      return;
    }
    this.setIsSubmitting(true);

    try {
      const result = await api.post(
        `payments/${paymentId}/optionpay/fetchPaymentUrl`,
        { amount: amount.toString() },
      );
      if (result.status === ApiStatus.SUCCESS) {
        await api.patch('payments', {
          id: paymentId,
          method: PaymentMethodApi.DEBIT_CREDIT_CARD,
          status: PaymentStatusApi.PENDING,
        });
        window.location.href = result.data;
      }
    } catch (error) {
      console.error(error);
      onError();
    } finally {
      this.setIsSubmitting(false);
    }
  }
}

export default OptionPayCardStore;
