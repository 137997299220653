import { Elements } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import useStripe from 'src/pages/payment-page/hooks/use-stripe';
import { Loader } from 'src/components/common-components';
import SquareCard from 'src/square/components/square-card';
import SquareForm from 'src/square/components/square-form';
import BankTransferForm from '../bank-transfer-form';
import CashForm from '../cash-form';
import OptionPayForm from '../option-pay-form';
import PaypalButton from '../paypal-button';
import StripeCard from '../stripe-card';

import {
  CARD_PAYMENT_VENDOR_NAME,
  PaymentMethod,
  PaymentMethodApi,
  paymentMethodOptions,
} from 'src/constants';
import type { PaymentMethodFormProps } from './payment-method-form.props';

import './payment-method-form.scss';

const PaymentMethodForm = observer(
  ({
    bankTransferStore,
    cashCheckStore,
    isMorePaymentsAhead,
    isPaymentAmount,
    loadingPayment,
    paymentOption,
    paypalStore,
    squareCardStore,
    stripeAccount,
    stripeCardStore,
  }: PaymentMethodFormProps) => {
    const { stripePromise } = useStripe(stripeAccount);
    const extraDataClassNames = {
      [PaymentMethod[PaymentMethodApi.BANK_TRANSFER]]:
        'payment-method-form_bank-transfer',
    };

    const renderPaymentMethod = (method: IPaymentMethod) => {
      switch (method) {
        case 'card':
          return CARD_PAYMENT_VENDOR_NAME === 'stripe' ? (
            stripePromise && stripeCardStore ? (
              <Elements
                key={method}
                options={{ locale: 'en' }}
                stripe={stripePromise}
              >
                <StripeCard
                  isCardCanBeSaved={isMorePaymentsAhead}
                  isVisible={
                    paymentOption.apiValue ===
                    PaymentMethodApi.DEBIT_CREDIT_CARD
                  }
                  loadingPayment={loadingPayment}
                  stripeCardStore={stripeCardStore}
                />
              </Elements>
            ) : (
              <Loader key={method} />
            )
          ) : CARD_PAYMENT_VENDOR_NAME === 'square' && squareCardStore ? (
            <SquareForm
              key={method}
              isVisible={
                paymentOption.apiValue === PaymentMethodApi.DEBIT_CREDIT_CARD
              }
            >
              <SquareCard squareCardStore={squareCardStore} />
            </SquareForm>
          ) : CARD_PAYMENT_VENDOR_NAME === 'optionPay' ? (
            <OptionPayForm
              key={method}
              isVisible={
                paymentOption.apiValue === PaymentMethodApi.DEBIT_CREDIT_CARD
              }
            />
          ) : (
            <></>
          );

        case 'bankTransfer':
          return bankTransferStore ? (
            <BankTransferForm
              key={method}
              bankTransferStore={bankTransferStore}
              isVisible={
                paymentOption.apiValue === PaymentMethodApi.BANK_TRANSFER
              }
            />
          ) : (
            <></>
          );

        case 'paypal':
          return paypalStore ? (
            <PaypalButton
              key={method}
              isDisabled={!isPaymentAmount}
              isVisible={paymentOption.apiValue === PaymentMethodApi.PAYPAL}
              paypalStore={paypalStore}
            />
          ) : (
            <></>
          );
        case 'cash':
          return cashCheckStore ? (
            <CashForm
              key={method}
              cashCheckStore={cashCheckStore}
              isVisible={paymentOption.apiValue === PaymentMethodApi.CASH}
              loadingPayment={loadingPayment}
            />
          ) : (
            <></>
          );

        default:
          return <></>;
      }
    };

    return (
      <div
        className={classNames(
          'payment-method-form',
          extraDataClassNames[paymentOption.value],
        )}
      >
        {!!paymentMethodOptions.length ? (
          paymentMethodOptions.map((option) => renderPaymentMethod(option.id))
        ) : (
          <Loader />
        )}
      </div>
    );
  },
);

export default PaymentMethodForm;
