import React from 'react';

import { Button } from 'src/components/ui-components';
import { ReactComponent as NoteIcon } from 'src/assets/icons/icon-note.svg';

import { DocumentName } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { checkAgreementShow, formatDate, openSignInLink } from 'src/utils';
import type { DocumentsListProps } from './documents-list.props';

import './documents-list.scss';

const DocumentsList = ({ documents, order }: DocumentsListProps) => {
  const { getLocalization } = useLocalization();

  const getAgreementDocument = () => {
    if (checkAgreementShow(order)) {
      return (
        <li className="documents-list__item">
          <NoteIcon className="documents-list__item-icon" />
          <span className="documents-list__item-info">
            <span className="documents-list__item-date">
              {getLocalization('Added')} {formatDate(order.contractDate)}
            </span>
            <span className="documents-list__item-name">
              {getLocalization(DocumentName.PURCHASE_AGREEMENT)}
            </span>
          </span>
          <Button
            label={getLocalization('Download')}
            size="mini"
            theme="outlined"
            onClick={() => openSignInLink(order.id, true)}
          />
        </li>
      );
    }

    return <></>;
  };

  const getOwnershipDocument = () => {
    const ownershipDocument = documents.find(
      (item) => item.type === 'deed' && !!item.auto_generated,
    );

    if (ownershipDocument) {
      const { id, created_at, filename } = ownershipDocument;

      return (
        <li className="documents-list__item" key={id}>
          <NoteIcon className="documents-list__item-icon" />
          <span className="documents-list__item-info">
            <span className="documents-list__item-date">
              {getLocalization('Added')} {formatDate(created_at)}
            </span>
            <span className="documents-list__item-name">
              {getLocalization(DocumentName.CERTIFICATE_OF_OWNERSHIP)}
            </span>
          </span>
          <Button
            download={getLocalization(DocumentName.CERTIFICATE_OF_OWNERSHIP)}
            href={filename}
            label={getLocalization('Download')}
            size="mini"
            tag="a"
            theme="outlined"
          />
        </li>
      );
    }

    return <></>;
  };

  return (
    <ul className="documents-list">
      {getAgreementDocument()}
      {getOwnershipDocument()}
    </ul>
  );
};

export default DocumentsList;
