import React from 'react';
import { useParams } from 'react-router-dom';

import { CustomScrollbar, Footer } from 'src/components/common-components';
import { Button } from 'src/components/ui-components';
import { ReactComponent as CheckIcon } from 'src/assets/icons/icon-check.svg';

import { AppRoute } from 'src/constants';
import { useLocalization } from 'src/contexts';

import './results-page.scss';

const ResultsPage = () => {
  const { getLocalization } = useLocalization();
  const { id } = useParams<{ id: string }>();

  return (
    <CustomScrollbar width="100%" height="100%">
      <div className="results-page">
        <div className="results-page__content">
          <div className="results-page__info">
            <div className="results-page__icon">
              <CheckIcon />
            </div>
            <div className="results-page__text-block">
              <div className="results-page__text">
                {getLocalization(
                  'The Purchase Agreement has been signed successfully.',
                )}
              </div>
              <div className="results-page__text">
                {getLocalization('Now you can make a payment')}
              </div>
            </div>
          </div>
          <div className="results-page__buttons">
            <Button
              className="results-page__payment-button"
              href={`${AppRoute.PAYMENT}/${id}`}
              label={getLocalization('Make a Payment')}
              size="oversize"
              tag="link"
              theme="filled"
            />
            <Button
              className="results-page__back-button"
              href={AppRoute.DASHBOARD}
              label={getLocalization('Back to Main page')}
              size="oversize"
              tag="link"
              theme="outlined"
            />
          </div>
        </div>
        <Footer />
      </div>
    </CustomScrollbar>
  );
};

export default ResultsPage;
