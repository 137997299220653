import React, { useLayoutEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import OrderItem from 'src/components/common-components/order-item';
import ButtonIcon from 'src/components/ui-components/button-icon';
import { ReactComponent as IconExit } from 'src/assets/icons/icon-exit.svg';

import globalStore from 'src/stores/global-store';
import ordersStore from 'src/stores/orders-store';

import { AppRoute, MenuItemStatus, PaidItemStatus, Size } from 'src/constants';
import { useLocalization } from 'src/contexts';
import useOnClickOutside from 'src/hooks/use-on-click-outside';
import useWindowSize from 'src/hooks/use-window-size';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { OrdersMenuProps } from './orders-menu.props';

import './orders-menu.scss';

const VISIBLE_ORDERS_COUNT = 3;
const BUTTON_HEIGHT = 32;

const OrdersMenu = observer(({ className }: OrdersMenuProps) => {
  const { getLocalization } = useLocalization();
  const windowSize = useWindowSize();
  const ref = useRef<HTMLUListElement>(null);

  useOnClickOutside(ref, ordersStore.toggleModal);

  useLayoutEffect(() => {
    const menuList = ref.current;
    if (!menuList) {
      return;
    }
    const headerHeight =
      (document.querySelector('.header') as HTMLElement)?.offsetHeight ?? 0;

    if (windowSize.width >= Size.TABLET_WIDTH) {
      const listPosition = headerHeight / 2 + BUTTON_HEIGHT; //Position must be under burger button. If header padding will be change, then need to change this formula.
      menuList.style.top = `${listPosition}px`;
    } else {
      menuList.style.top = `${headerHeight}px`;
    }

    const items = Array.from(menuList.children) as HTMLLIElement[];
    const fourthItemTop = items.filter(
      (item) => item.className === 'order-item',
    )[VISIBLE_ORDERS_COUNT]?.offsetTop;
    const linkHeight =
      items.find((item) => item.className === 'orders-menu__item-link')
        ?.offsetHeight ?? 0;

    menuList.style.height = fourthItemTop
      ? `${fourthItemTop + linkHeight}px`
      : `auto`;
  }, [windowSize.width]);

  const closeOrdersMenu = () => {
    ordersStore.setIsModalShown(false);
  };

  return (
    <ul className={classNames('orders-menu', 'scrollbar', className)} ref={ref}>
      <li className="orders-menu__user-controls">
        <span className="orders-menu__user-controls-text">
          {globalStore.user}
        </span>
        <div className="orders-menu__user-controls-wrapper">
          <span className="orders-menu__user-controls-description">
            {getLocalization('Sign out')}
          </span>
          <ButtonIcon
            className="orders-menu__user-controls-logout-button"
            ariaLabel={getLocalization('Log out')}
            icon={<IconExit />}
            size="xs"
            onClick={globalStore.logOut}
          />
        </div>
      </li>
      {ordersStore.signinOrders.map((order) => (
        <OrderItem
          key={order.id}
          agreementNumber={order.agreementNumber}
          item={order}
          status={getLocalization(MenuItemStatus.SIGNING)}
        />
      ))}
      {ordersStore.activeOrders.map((order) => {
        const nextPayment = ordersStore.getOrderNextPayment(order.id);
        return (
          <OrderItem
            key={order.id}
            agreementNumber={order.agreementNumber}
            item={order}
            status={`${getLocalization(
              MenuItemStatus.NEXT_PAYMENT,
            )}: ${formatAsUSDOptionalCents(nextPayment)}`}
          />
        );
      })}
      {ordersStore.completedOrders.map((order) => (
        <OrderItem
          key={order.id}
          agreementNumber={order.agreementNumber}
          item={order}
          status={getLocalization(PaidItemStatus[order.status])}
        />
      ))}
      <li className="orders-menu__item-link">
        <Link
          className="orders-menu__link"
          to={AppRoute.DASHBOARD}
          onClick={closeOrdersMenu}
        >
          {getLocalization('Go to Agreements list')}
        </Link>
      </li>
    </ul>
  );
});

export default OrdersMenu;
