import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Button } from 'src/components/ui-components';
import OrderAccordion from '../order-accordion';
import OrderActions from '../order-actions';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderType } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { formatAsUSDOptionalCents, formatDate, getLot } from 'src/utils';
import type { OrderProps } from './order.props';

import './order.scss';

const Order = observer(
  ({
    className,
    id,
    additionalInfo = '',
    agreementNumber,
    balance,
    createdAt,
    dueDate,
    list,
    lots,
    payment,
    price,
    type,
  }: OrderProps) => {
    const { getLocalization } = useLocalization();
    const formattedDate = createdAt ? formatDate(createdAt) : '';

    const handleDetailsButtonClick = () => {
      ordersStore.setSelectedOrderId(id);
    };

    return (
      <li className={classNames('order', className)}>
        <div className="order__wrapper">
          <div className="order__info-container">
            <div className="order__info">
              <div className="order__info-number">
                <div className="order__title-container">
                  <p className="order__title">{agreementNumber}</p>
                  <span className="order__date">{formattedDate}</span>
                </div>
                {additionalInfo && (
                  <p className="order__additional-data">{additionalInfo}</p>
                )}
                {!!lots.length && (
                  <div className="order__lots">
                    {lots.map((lot) => (
                      <span className="order__lot" key={lot.number}>
                        {getLot(lot, getLocalization)}
                      </span>
                    ))}
                  </div>
                )}
              </div>
              {type !== OrderType.SIGNING && (
                <Button
                  className="order__details-button"
                  href={`${AppRoute.DASHBOARD}/${id}`}
                  label={getLocalization('Details')}
                  size="flattened"
                  tag="link"
                  theme="outlined"
                  onClick={handleDetailsButtonClick}
                />
              )}
            </div>
            <div className="order__price-container">
              <div className="order__price">
                <span className="order__price-title">
                  {getLocalization('Total cost')}:
                </span>
                <span className="order__price-value">{price}</span>
              </div>
              <div className="order__price">
                <span className="order__price-title">
                  {getLocalization('Remaining balance')}:
                </span>
                <span className="order__price-value">
                  {type === OrderType.SIGNING
                    ? price
                    : formatAsUSDOptionalCents(balance || 0)}
                </span>
              </div>
            </div>
          </div>
          {(type === OrderType.ACTIVE || type === OrderType.SIGNING) && (
            <OrderActions id={id} type={type} payment={payment} />
          )}
        </div>
        {type === OrderType.ACTIVE && list && (
          <OrderAccordion list={list} currentDate={dueDate} />
        )}
      </li>
    );
  },
);

export default Order;
