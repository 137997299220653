import classNames from 'classnames';

import optionPayLogo from 'src/assets/icons/logo-option-pay.png';

import { useLocalization } from 'src/contexts';
import type { OptionPayFormProps } from './option-pay-form.props';

import './option-pay-form.scss';

const OptionPayForm = ({ isVisible }: OptionPayFormProps) => {
  const { getLocalization } = useLocalization();

  return (
    <div
      className={classNames(
        'option-pay-form',
        !isVisible && 'option-pay-form_hidden',
      )}
    >
      <div className="option-pay-form__intro">
        <p className="option-pay-form__intro-text">
          {getLocalization('Make a payment through OptionPay')}
        </p>
        <img
          className="option-pay-form__intro-logo"
          alt="OptionPay"
          src={optionPayLogo}
        />
      </div>
    </div>
  );
};

export default OptionPayForm;
