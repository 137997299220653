import React from 'react';
import classNames from 'classnames';

import { ArrowButton } from 'src/components/ui-components';

import { useLocalization } from 'src/contexts';
import type { PaginationProps } from './pagination.props';

import './pagination.scss';

const Pagination = ({
  className,
  endListCount,
  isFirstPage,
  isLastPage,
  startListCount,
  totalDataCount,
  onFirstPageButtonClick,
  onLastPageButtonClick,
  onNextPageButtonClick,
  onPreviousPageButtonClick,
}: PaginationProps) => {
  const { getLocalization } = useLocalization();

  return (
    <div className={classNames('pagination', className)}>
      <div className="pagination__buttons">
        <ArrowButton
          className="pagination__button"
          ariaLabel={getLocalization('To first page')}
          direction="left"
          isDisabled={isFirstPage}
          isDouble
          onClick={onFirstPageButtonClick}
        />
        <ArrowButton
          className="pagination__button"
          ariaLabel={getLocalization('To previous page')}
          direction="left"
          isDisabled={isFirstPage}
          onClick={onPreviousPageButtonClick}
        />
      </div>
      <div className="pagination__numbers">
        {`${startListCount}${
          startListCount !== endListCount ? `-${endListCount}` : ``
        }`}
        &nbsp;{getLocalization('of')}&nbsp;{totalDataCount}
      </div>
      <div className="pagination__buttons">
        <ArrowButton
          className="pagination__button"
          ariaLabel={getLocalization('To next page')}
          direction="right"
          isDisabled={isLastPage}
          onClick={onNextPageButtonClick}
        />
        <ArrowButton
          className="pagination__button"
          ariaLabel={getLocalization('To last page')}
          direction="right"
          isDisabled={isLastPage}
          isDouble
          onClick={onLastPageButtonClick}
        />
      </div>
    </div>
  );
};

export default Pagination;
