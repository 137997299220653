import React, { useRef } from 'react';

import { Button } from 'src/components/ui-components';
import Popup from '../popup';

import { AppRoute } from 'src/constants';
import { useLocalization } from 'src/contexts';
import useWindowScrollBlock from 'src/hooks/use-window-scroll-block';
import type { PopupPaymentSuccessProps } from './popup-payment-success.props';

const PopupPaymentSuccess = ({ id }: PopupPaymentSuccessProps) => {
  const { getLocalization } = useLocalization();
  const popupRef = useRef(null);

  useWindowScrollBlock(true);

  return (
    <Popup className="popup_success">
      <div className="popup__wrapper" ref={popupRef}>
        <div className="popup__success-container">
          <p className="popup__success-title">
            {getLocalization('Thank You')}!
          </p>
          <span className="popup__success-text">
            {getLocalization('Payment successfully completed')}
          </span>
        </div>
        <Button
          className="popup__success-button"
          href={`${AppRoute.DASHBOARD}/${id}`}
          label={getLocalization('Back to Transaction')}
          size="oversize"
          tag="link"
          theme="filled"
        />
      </div>
    </Popup>
  );
};

export default PopupPaymentSuccess;
