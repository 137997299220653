import React from 'react';

import { Table } from 'src/components/common-components';

import { useLocalization } from 'src/contexts';
import { getFormattedServices } from 'src/utils';
import type { ServicesListProps } from './services-list.props';

import './services-list.scss';

const ServicesList = ({ services }: ServicesListProps) => {
  const { getLocalization } = useLocalization();

  return (
    <Table
      className="table_services"
      columns={[getLocalization('Service Name'), getLocalization('Price')]}
      rows={getFormattedServices(services, getLocalization).map(
        ({ name, price }) => [
          name,
          { className: 'table__cell_bold', value: price },
        ],
      )}
    />
  );
};

export default ServicesList;
