import { useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { cardPaymentVendorProps } from 'src/constants';

export default function useStripe(stripeAccount: string = '') {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);

  useEffect(() => {
    const fetchStripeObject = async () => {
      if (
        stripeAccount &&
        cardPaymentVendorProps?.vendor === 'stripe' &&
        !!cardPaymentVendorProps?.stripePublicKey
      ) {
        const result = await loadStripe(
          cardPaymentVendorProps.stripePublicKey,
          {
            stripeAccount,
          },
        );
        setStripePromise(result);
      }
    };
    fetchStripeObject();
  }, [stripeAccount]);

  return {
    stripePromise,
  };
}
