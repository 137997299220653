import React from 'react';

import { Pagination, Table } from 'src/components/common-components';

import { InstalmentStatus } from 'src/constants';
import { useLocalization } from 'src/contexts';
import usePagination from 'src/hooks/use-pagination';
import {
  formatAsUSDOptionalCents,
  formatDate,
  getPaymentStatus,
} from 'src/utils';
import type { PaymentsListProps } from './payments-list.props';

import './payments-list.scss';

const PaymentsList = ({ orderDueDate, payments }: PaymentsListProps) => {
  const { getLocalization } = useLocalization();
  const {
    list,
    decrementPage,
    endListCount,
    incrementPage,
    isShowPagination,
    pageCount,
    setFirstPage,
    setLastPage,
    skipDataCount,
    startListCount,
    totalDataCount,
  } = usePagination(payments);

  return (
    <>
      <Table
        className="table_payments"
        columns={[
          getLocalization('Regular Payment Date'),
          getLocalization('Regular Payment'),
          getLocalization('Paid Amount'),
          getLocalization('Paid Date'),
          getLocalization('Status'),
        ]}
        rows={list.map(
          ({ dueDate, amount, paidAmount, paymentDate, status }, index) => {
            const statusValue = getPaymentStatus(
              status,
              dueDate,
              orderDueDate,
              pageCount,
              index,
            );
            const className = `table__cell_status table__cell_${statusValue.toLowerCase()}`;
            const formatCell = (value: string) =>
              statusValue === InstalmentStatus.MISSED
                ? { className, value }
                : value;

            return [
              formatCell(formatDate(dueDate)),
              formatCell(formatAsUSDOptionalCents(amount)),
              formatCell(
                paidAmount ? formatAsUSDOptionalCents(paidAmount) : '-',
              ),
              formatCell(paymentDate ? formatDate(paymentDate) : '-'),
              {
                className,
                value: getLocalization(statusValue),
              },
            ];
          },
        )}
      />

      {isShowPagination && (
        <Pagination
          className="payments-list__pagination"
          endListCount={endListCount}
          isFirstPage={pageCount === 1}
          isLastPage={list.length + skipDataCount >= totalDataCount}
          startListCount={startListCount}
          totalDataCount={totalDataCount}
          onFirstPageButtonClick={setFirstPage}
          onLastPageButtonClick={setLastPage}
          onNextPageButtonClick={incrementPage}
          onPreviousPageButtonClick={decrementPage}
        />
      )}
    </>
  );
};

export default PaymentsList;
