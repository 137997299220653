export const CONFIG_REQUIRED_PARAMS: (keyof Window)[] = [
  'API_URL',
  'CEMETERY_URL',
  'PAYMENT_SETTINGS',
  'WS_URL',
];

export const CONFIG_REQUIRED_PARAMS_MAPPED = CONFIG_REQUIRED_PARAMS.map(
  (item) => ({
    name: item,
    value: window[item],
  }),
);

export const THEME_REQUIRED_PROPERTY = '--color-theme-accent';
