import React, { useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import LoginInputs from 'src/pages/login-page/components/login-inputs';
import { ButtonIcon } from 'src/components/ui-components';
import { ReactComponent as EditIcon } from 'src/assets/icons/icon-edit.svg';

import AuthStore from 'src/stores/auth-store';

import { LoginStep } from 'src/constants';
import { useLocalization } from 'src/contexts';
import type { LoginFormProps } from './login-form.props';

import './login-form.scss';

const LoginForm = observer(({ className }: LoginFormProps) => {
  const [authStore] = useState(() => new AuthStore());
  const { getLocalization } = useLocalization();

  const renderHeader = () => {
    if (authStore.step === LoginStep.CODE_ENTER) {
      return (
        <>
          {getLocalization('Authorization code sent to')}
          <span className="login-form__text-part login-form__text-part_bold">
            {authStore.phone}
            <ButtonIcon
              className="login-form__reset-button"
              ariaLabel={getLocalization('Edit phone number')}
              icon={<EditIcon />}
              onClick={authStore.resetPhone}
            />
          </span>
        </>
      );
    }

    return getLocalization('Enter your phone number to get authorization code');
  };

  return (
    <section className={classNames('login-form', className)}>
      <div className="login-form__wrapper">
        <div className="login-form__head">
          <div className="login-form__title-wrapper">
            <h1 className="login-form__title">
              {getLocalization('Sign In to Family Portal')}
            </h1>
            <p
              className={classNames(
                'login-form__text',
                authStore.step === LoginStep.CODE_ENTER &&
                  'login-form__text_code',
              )}
            >
              {renderHeader()}
            </p>
          </div>
          <LoginInputs className="login-form__inputs" store={authStore} />
        </div>
      </div>
    </section>
  );
});

export default LoginForm;
