import { CardNumberElement } from '@stripe/react-stripe-js';
import type {
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElements,
} from '@stripe/stripe-js';
import { makeAutoObservable } from 'mobx';

import type PaymentFormStore from 'src/stores/payment-form/payment-form-store';
import processingStore from 'src/stores/processing-store';

import api from 'src/api';
import type { ILocation, IOrder } from 'src/interfaces';

const initialBillingAddress = {
  building: '',
  street: '',
  room: '',
  city: '',
  state: '',
  zip: '',
};

const getLine2Address = (building?: string, room?: string) => {
  if (!building && !room) {
    return undefined;
  }

  if (building && room) {
    return `${building} ${room}`;
  }

  return building || room;
};

class StripeCardStore {
  billingAddress: ILocation = initialBillingAddress;
  isSameBillingAddress: boolean = true;
  isCardElementComplete: boolean = false;
  isCardCvcComplete: boolean = false;
  isCardExpiryComplete: boolean = false;
  shouldSaveCard: boolean = false;
  setFormError: PaymentFormStore['setFormError'];

  // stripe-js
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;

  constructor(
    setFormError: PaymentFormStore['setFormError'],
    purchaserAddress?: ILocation,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.setFormError = setFormError;
    if (purchaserAddress) {
      this.setBillingAddress(purchaserAddress);
    }
  }

  setBillingAddress(billingAddress: ILocation) {
    this.billingAddress = billingAddress;
  }

  setIsSameBillingAddress(isSame: boolean) {
    this.isSameBillingAddress = isSame;
  }

  setIsCardElementComplete(isComplete: boolean) {
    this.isCardElementComplete = isComplete;
  }

  setIsCardCvcComplete(isComplete: boolean) {
    this.isCardCvcComplete = isComplete;
  }

  setIsCardExpiryComplete(isComplete: boolean) {
    this.isCardExpiryComplete = isComplete;
  }

  setShouldSaveCard(shouldSaveCard: boolean) {
    this.shouldSaveCard = shouldSaveCard;
  }

  setStripe(stripe: Stripe | null) {
    this.stripe = stripe;
  }

  setElements(elements: StripeElements | null) {
    this.elements = elements;
  }

  toggleIsSameBillingAddress() {
    this.isSameBillingAddress = !this.isSameBillingAddress;
  }

  toggleShouldSaveCard() {
    this.shouldSaveCard = !this.shouldSaveCard;
  }

  handleCardElementChange(evt: StripeCardNumberElementChangeEvent) {
    this.setIsCardElementComplete(evt.complete);
    this.setFormError(evt.error?.message || '');
  }

  handleCvcElementChange(evt: StripeCardCvcElementChangeEvent) {
    this.setIsCardCvcComplete(evt.complete);
    this.setFormError(evt.error?.message || '');
  }

  handleExpiryElementChange(evt: StripeCardExpiryElementChangeEvent) {
    this.setIsCardExpiryComplete(evt.complete);
    this.setFormError(evt.error?.message || '');
  }

  get isFormValid() {
    return (
      this.isCardElementComplete &&
      this.isCardCvcComplete &&
      this.isCardExpiryComplete &&
      !!this.stripe &&
      !!this.elements
    );
  }

  async sendCardPaymentRequest(
    amount: number,
    paymentId: string,
    order: IOrder,
    onError: (err?: string) => void,
  ) {
    const cardElement = this.elements?.getElement(CardNumberElement);

    if (!cardElement || !this.stripe || !this.isFormValid) {
      onError();
      return;
    }

    try {
      const { data: clientKey } = await api.get(
        `payments/${paymentId}/paymentIntent?amount=${amount}&recurring=${this.shouldSaveCard}`,
      );

      if (clientKey) {
        const line2 = getLine2Address(
          this.billingAddress.building,
          this.billingAddress.room,
        );
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(
          clientKey,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: order.purchasers[0].name,
                email: order.purchasers[0].email,
                phone: order.purchasers[0].phone,
                address: {
                  city: this.billingAddress.city,
                  country: 'US',
                  line1: this.billingAddress.street,
                  line2,
                  state: this.billingAddress.state,
                  postal_code: this.billingAddress.zip,
                },
              },
            },
          },
        );
        if (paymentIntent && paymentId) {
          processingStore.setPaymentPendingProcessOn(paymentId);
        } else if (error) {
          onError(error?.message);
        }
      } else {
        onError();
      }
    } catch (err) {
      console.error(err);
      onError();
    }
  }
}

export default StripeCardStore;
