import React from 'react';

import { Button } from 'src/components/ui-components';

import { AppRoute } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { NextPaymentProps } from './next-payment.props';

import './next-payment.scss';

const NextPayment = ({
  id,
  isNextPaymentDisabled,
  total,
}: NextPaymentProps) => {
  const { getLocalization } = useLocalization();

  return (
    <div className="next-payment">
      <div className="next-payment__data">
        <div className="next-payment__data-label">
          {getLocalization('Next Payment')}:
        </div>
        <div className="next-payment__data-value">
          {formatAsUSDOptionalCents(total)}
        </div>
      </div>
      <Button
        className="next-payment__button"
        href={`${AppRoute.PAYMENT}/${id}`}
        isDisabled={isNextPaymentDisabled}
        label={getLocalization('Pay now')}
        size="normal"
        tag="link"
        theme="filled"
      />
    </div>
  );
};

export default NextPayment;
