import classNames from 'classnames';

import type { SquareFormProps } from './square-form.props';

import './square-form.scss';

const SquareForm = ({ children, className, isVisible }: SquareFormProps) => (
  <div
    className={classNames(
      'square-form',
      !isVisible && 'square-form_hidden',
      className,
    )}
    role="form"
  >
    <div className="square-form__inputs">{children}</div>
  </div>
);

export default SquareForm;
