import React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Button, Input } from 'src/components/ui-components';

import { Mask } from 'src/constants';
import { useLocalization } from 'src/contexts';
import type { CodeEnterProps } from './code-enter.props';

import './code-enter.scss';

const CodeEnter = observer(({ className, store }: CodeEnterProps) => {
  const { getLocalization } = useLocalization();

  return (
    <div className={classNames('code-enter', className)}>
      <div className="code-enter__data">
        <Input
          id="codeEnterInput"
          isDisabled={store.isLoading}
          isSpacedError
          isValid={!store.codeRequestError}
          label={getLocalization('SMS Code')}
          mask={Mask.CODE}
          textError={getLocalization(store.codeRequestError)}
          value={store.code}
          onChange={store.setCode}
        />
      </div>
      <div className="code-enter__buttons">
        <Button
          isDisabled={!store.isFormCodeValid || store.isLoading}
          label={getLocalization('Sign In')}
          size="oversize"
          theme="filled"
          onClick={store.sendCodeCheckRequest}
        />
        <Button
          isDisabled={store.isLoading || store.isNewCodeRequestDisabled}
          label={getLocalization('Get New Code')}
          size="oversize"
          theme="outlined"
          onClick={store.getNewVerificationCode}
        />
        {store.isNewCodeRequestDisabled && (
          <p className="code-enter__data-hint">
            {getLocalization('New code is sent. You can resend it in')}{' '}
            <span className="code-enter__data-hint-time">
              {store.timerRemainingTime.replace(/(min|sec)/g, getLocalization)}.
            </span>
          </p>
        )}
      </div>
    </div>
  );
});

export default CodeEnter;
