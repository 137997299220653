import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Tabs } from 'src/components/common-components';
import DocumentsList from '../documents-list';
import PaymentsList from '../payments-list';
import PropertyList from '../property-list';
import ServicesList from '../services-list';

import ordersStore from 'src/stores/orders-store';

import { useLocalization } from 'src/contexts';

import './details-page-content.scss';

enum DetailsTab {
  PAYMENTS = 'Payments',
  PROPERTY = 'Property',
  SERVICES = 'Services',
  DOCUMENTS = 'Documents',
}

const DetailsPageContent = observer(() => {
  const { getLocalization } = useLocalization();
  const [activeTab, setActiveTab] = useState<DetailsTab>(DetailsTab.PAYMENTS);

  const getTab = (tabName: DetailsTab) => ({
    name: tabName,
    label: getLocalization(tabName),
    handleClick: () => setActiveTab(tabName),
  });

  const renderContent = () => {
    const { selectedOrder, isOrderPaymentsLoaded } = ordersStore;

    if (!selectedOrder || !isOrderPaymentsLoaded) {
      return null;
    }

    switch (activeTab) {
      case DetailsTab.PAYMENTS: {
        const { selectedOrderDueDate, selectedOrderPaymentsList } = ordersStore;
        return (
          <PaymentsList
            orderDueDate={selectedOrderDueDate}
            payments={selectedOrderPaymentsList}
          />
        );
      }

      case DetailsTab.PROPERTY:
        return (
          <PropertyList
            lots={
              selectedOrder.inventoryLot
                ? [selectedOrder.inventoryLot]
                : selectedOrder.lots ?? []
            }
          />
        );

      case DetailsTab.SERVICES:
        return <ServicesList services={selectedOrder.services ?? []} />;

      case DetailsTab.DOCUMENTS:
        return (
          <DocumentsList
            documents={selectedOrder.documents ?? []}
            order={selectedOrder}
          />
        );
    }
  };

  return (
    <div className="details-page-content">
      <Tabs
        className="tabs_details"
        activeTab={activeTab}
        tabs={[
          getTab(DetailsTab.PAYMENTS),
          getTab(DetailsTab.PROPERTY),
          getTab(DetailsTab.SERVICES),
          getTab(DetailsTab.DOCUMENTS),
        ]}
      />
      {renderContent()}
    </div>
  );
});

export default DetailsPageContent;
