import { observer } from 'mobx-react-lite';

import Order from '../order';

import ordersStore from 'src/stores/orders-store';

import { OrderType } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { getOrderAdditionalLabel } from 'src/utils';

import './orders.scss';

const Orders = observer(() => {
  const { getLocalization } = useLocalization();

  return (
    <ul className="orders">
      {ordersStore.signinOrders.map((item) => (
        <Order
          id={item.id}
          key={item.id}
          additionalInfo={getLocalization(getOrderAdditionalLabel(item))}
          agreementNumber={item.agreementNumber}
          balance={ordersStore.getOrderRemainingBalance(item.id)}
          createdAt={item.createdAt}
          dueDate={ordersStore.getOrderDueDate(item.id)}
          lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
          payment={ordersStore.getOrderNextPayment(item.id)}
          price={item.payment?.total}
          type={OrderType.SIGNING}
        />
      ))}
      {ordersStore.activeOrders.map((item) => (
        <Order
          id={item.id}
          key={item.id}
          additionalInfo={getLocalization(getOrderAdditionalLabel(item))}
          agreementNumber={item.agreementNumber}
          balance={ordersStore.getOrderRemainingBalance(item.id)}
          createdAt={item.createdAt}
          dueDate={ordersStore.getOrderDueDate(item.id)}
          list={ordersStore.getOrderPaymentList(item.id)}
          lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
          price={item.payment?.total}
          payment={ordersStore.getOrderNextPayment(item.id)}
          type={OrderType.ACTIVE}
        />
      ))}
      {ordersStore.completedOrders.map((item) => (
        <Order
          id={item.id}
          key={item.id}
          additionalInfo={getLocalization(getOrderAdditionalLabel(item))}
          agreementNumber={item.agreementNumber}
          balance={ordersStore.getOrderRemainingBalance(item.id)}
          createdAt={item.createdAt}
          dueDate={ordersStore.getOrderDueDate(item.id)}
          lots={item.inventoryLot ? [item.inventoryLot] : item.lots || []}
          price={item.payment?.total}
          type={OrderType.COMPLETED}
        />
      ))}
    </ul>
  );
});

export default Orders;
