import React from 'react';
import dayjs from 'dayjs';

import { Pagination } from 'src/components/common-components';

import usePagination from 'src/hooks/use-pagination';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { InstalmentsListProps } from './instalments-list.props';

import './instalments-list.scss';

const InstalmentsList = ({ payments }: InstalmentsListProps) => {
  const {
    list,
    decrementPage,
    endListCount,
    incrementPage,
    isShowPagination,
    pageCount,
    setFirstPage,
    setLastPage,
    skipDataCount,
    startListCount,
    totalDataCount,
  } = usePagination(payments);

  return (
    <div className="instalments-list">
      <div className="instalments-list__content">
        <ul className="instalments-list__list">
          {list.map((item, index) => (
            <li key={item.id} className="instalments-list__list-item">
              <span className="instalments-list__list-item-text">
                {index + 1 + skipDataCount}
              </span>
              <span className="instalments-list__list-item-text">
                {dayjs(item.dueDate).format('MMM DD, YYYY')}
              </span>
              <span className="instalments-list__list-item-text">
                {formatAsUSDOptionalCents(item.paidAmount || item.amount)}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {isShowPagination && (
        <Pagination
          className="instalments-list__pagination"
          endListCount={endListCount}
          isFirstPage={pageCount === 1}
          isLastPage={list.length + skipDataCount >= totalDataCount}
          startListCount={startListCount}
          totalDataCount={totalDataCount}
          onFirstPageButtonClick={setFirstPage}
          onLastPageButtonClick={setLastPage}
          onNextPageButtonClick={incrementPage}
          onPreviousPageButtonClick={decrementPage}
        />
      )}
    </div>
  );
};

export default InstalmentsList;
