import type { KeyboardEvent } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import {
  Button,
  ButtonLink,
  Checkbox,
  Input,
  TextError,
} from 'src/components/ui-components';

import { AppRoute, KeyCode, Mask } from 'src/constants';
import { useLocalization } from 'src/contexts';
import useToggle from 'src/hooks/use-toggle';
import type { PhoneEnterProps } from './phone-enter.props';

import './phone-enter.scss';

const PhoneEnter = observer(({ className, store }: PhoneEnterProps) => {
  const { getLocalization } = useLocalization();
  const { isActive: isTermsAccepted, toggle: toggleCheckbox } = useToggle(true);
  const isButtonDisabled =
    !store.isPhoneValid || store.isLoading || !isTermsAccepted;

  const handleKeyPress = (evt: KeyboardEvent) => {
    if (evt.code === KeyCode.ENTER && !isButtonDisabled) {
      void store.getVerificationCode();
    }
  };

  return (
    <div className={classNames('phone-enter', className)}>
      <div className="phone-enter__container">
        <Input
          className="phone-enter__input"
          id="phoneEnterInput"
          isValid={!store.phoneRequestError}
          isDisabled={store.isLoading}
          label={getLocalization('Phone Number')}
          mask={Mask.PHONE}
          value={store.phone}
          onChange={store.setPhone}
          onKeyPress={handleKeyPress}
        />
        {store.phoneRequestError && (
          <TextError
            className="phone-enter__error"
            textError={getLocalization(store.phoneRequestError)}
          />
        )}
        <Checkbox
          className="phone-enter__checkbox"
          id="termsCheckbox"
          isChecked={isTermsAccepted}
          isDisabled={store.isLoading}
          onChange={toggleCheckbox}
        >
          <span className="phone-enter__terms">
            {getLocalization('I agree with')}{' '}
            <ButtonLink
              className="phone-enter__terms-link"
              isDisabled={store.isLoading}
              isUnderline
              label={getLocalization('Terms of Use')}
              tag="link"
              target="_blank"
              to={AppRoute.TERMS}
            />{' '}
            &amp;{' '}
            <ButtonLink
              className="phone-enter__terms-link"
              isDisabled={store.isLoading}
              isUnderline
              label={getLocalization('Privacy Policy')}
              tag="link"
              target="_blank"
              to={AppRoute.POLICY}
            />
          </span>
        </Checkbox>
      </div>
      <Button
        className="phone-enter__submit-btn"
        isDisabled={isButtonDisabled}
        label={getLocalization('Get the Code')}
        size="oversize"
        theme="filled"
        onClick={store.getVerificationCode}
      />
    </div>
  );
});

export default PhoneEnter;
