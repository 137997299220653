import type { Card, CardInputEvent, SqEvent } from '@square/web-sdk';
import { makeAutoObservable } from 'mobx';

import type PaymentFormStore from 'src/stores/payment-form/payment-form-store';

import api from 'src/api';
import { Error as ErrorText } from 'src/constants';
import squareStore from 'src/square/stores/square-store';

const cardOptions = {
  style: {
    input: {
      color: '#3b3b3b',
      fontSize: '16px',
      fontWeight: '400',
    },
    'input::placeholder': {
      color: '#b9b9b9',
    },
    'input.is-error': {
      color: '#d95151',
    },
    '.input-container': {
      borderColor: '#b0b0b0',
      borderRadius: '8px',
      borderWidth: '1px',
    },
    '.input-container.is-focus': {
      borderColor: '#35cdfd',
      borderWidth: '2px',
    },
    '.input-container.is-error': {
      borderColor: '#f9919a',
    },
    '.message-text.is-error': {
      color: '#d95151',
    },
    '.message-icon.is-error': {
      color: '#d95151',
    },
  },
};

class SquareCardStore {
  card: Card | undefined;
  isCardElementComplete: boolean = false;
  isCardDataError: boolean = false;
  isInitProcessing: boolean = false;
  isSubmitting: boolean = false;
  setFormError: PaymentFormStore['setFormError'];

  constructor(setFormError: PaymentFormStore['setFormError']) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.setFormError = setFormError;
  }

  setCard(card: Card | undefined) {
    this.card = card;
  }

  setIsCardElementComplete(isComplete: boolean) {
    this.isCardElementComplete = isComplete;
  }

  setIsCardDataError(isError: boolean) {
    this.isCardDataError = isError;
  }

  setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  setIsInitProcessing(isInitProcessing: boolean) {
    this.isInitProcessing = isInitProcessing;
  }

  handleCardError(cardInputEvent: SqEvent<CardInputEvent>) {
    const isError = cardInputEvent.detail.currentState.hasErrorClass;
    this.setFormError(isError ? ErrorText.DEFAULT : '');
    this.setIsCardDataError(isError);
  }

  handleCardInputChange(cardInputEvent: SqEvent<CardInputEvent>) {
    const currentInputEventState = cardInputEvent.detail.currentState;
    const cardField = cardInputEvent.detail.field;
    this.setFormError('');

    switch (cardField) {
      case 'cardNumber':
        return this.setIsCardElementComplete(
          currentInputEventState.isCompletelyValid,
        );
      default:
        return;
    }
  }

  destroy() {
    this.card?.destroy();
    this.card?.removeEventListener(
      'focusClassRemoved',
      this.handleCardInputChange,
    );
    this.card?.removeEventListener('errorClassAdded', this.handleCardError);
    this.card?.removeEventListener('errorClassRemoved', this.handleCardError);
    this.setCard(undefined);
  }

  get isFormValid() {
    return (
      this.card &&
      this.isCardElementComplete &&
      !this.isSubmitting &&
      !this.isCardDataError
    );
  }

  async initCardPayments(id: string) {
    if (this.card || this.isInitProcessing) {
      return;
    }

    this.setIsInitProcessing(true);

    let payments = squareStore.paymentsInstance;

    try {
      if (!payments) {
        payments = await squareStore.loadSquarePayments();
      }
      const card = await payments?.card(cardOptions);

      if (card) {
        this.setCard(card);
        await card.attach(`#${id}`);
        card.addEventListener('focusClassRemoved', this.handleCardInputChange);
        card.addEventListener('errorClassAdded', this.handleCardError);
        card.addEventListener('errorClassRemoved', this.handleCardError);
      } else {
        this.setFormError(ErrorText.DEFAULT);
      }
    } catch (err) {
      console.log(err);
      this.setFormError(ErrorText.DEFAULT);
    } finally {
      this.setIsInitProcessing(false);
    }
  }

  async sendCardPaymentRequest(
    amount: number,
    paymentId: string,
    onError: (err?: string) => void,
  ) {
    if (this.isSubmitting) {
      return;
    }

    if (!this.card) {
      console.warn(
        'Credit Card button was clicked, but no Credit Card instance was found.',
      );
      onError();
      return;
    }

    this.setIsSubmitting(true);

    try {
      const result = await this.card.tokenize();

      if (result.status === 'OK') {
        await api.post(`payments/${paymentId}/paymentIntent/square/pay`, {
          amount,
          paymentIntent: result.token,
        });
      } else {
        let message = `Tokenization failed with status: ${result.status}`;
        if (result.errors) {
          message += ` and errors: ${JSON.stringify(result.errors)}`;
          throw new Error(message);
        }
        console.warn(message);
      }
    } catch (error) {
      console.error(error);
      onError();
    } finally {
      this.setIsSubmitting(false);
    }
  }
}

export default SquareCardStore;
