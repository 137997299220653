import React from 'react';
import classNames from 'classnames';

import { ExternalLink } from 'src/components/common-components';

import { ABOUT_LINK, AppRoute } from 'src/constants';
import { useLocalization } from 'src/contexts';
import type { FooterLinksProps } from './footer-links.props';

import './footer-links.scss';

const FooterLinks = ({ className, page = 'any' }: FooterLinksProps) => {
  const { getLocalization } = useLocalization();

  return (
    <div
      className={classNames(
        'footer-links',
        `footer-links_page_${page}`,
        className,
      )}
    >
      <ExternalLink className="footer-links__item" href={ABOUT_LINK}>
        {getLocalization('Cemetery Website')}
      </ExternalLink>
      <ExternalLink className="footer-links__item" href={AppRoute.POLICY}>
        {getLocalization('Privacy Policy')}
      </ExternalLink>
      <ExternalLink className="footer-links__item" href={AppRoute.TERMS}>
        {getLocalization('Terms of Use')}
      </ExternalLink>
    </div>
  );
};

export default FooterLinks;
