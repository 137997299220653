import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Loader } from 'src/components/common-components';

import type { SquareCardProps } from './square-card.props';

import './square-card.scss';

const SquareCard = observer(
  ({ id = 'square-card', squareCardStore }: SquareCardProps) => {
    const { card, destroy, initCardPayments } = squareCardStore;

    useEffect(() => {
      initCardPayments(id);
    }, [id, initCardPayments]);

    useEffect(() => () => destroy(), [destroy]);

    return (
      <div className="square-card" id={id}>
        {!card && <Loader />}
      </div>
    );
  },
);

export default SquareCard;
