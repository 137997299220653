import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import PlaidWrapper from '../plaid-wrapper';
import { ReactComponent as LogoPlaid } from 'src/assets/icons/logo-plaid.svg';

import { useLocalization } from 'src/contexts';
import type { BankTransferFormProps } from './bank-transfer-form.props';

import './bank-transfer-form.scss';

const BankTransferForm = observer(
  ({ bankTransferStore, isVisible }: BankTransferFormProps) => {
    const { getLocalization } = useLocalization();
    const {
      bankTransferAmount,
      plaidToken,
      handlePlaidSuccess,
      handlePlaidExit,
      setFormError,
    } = bankTransferStore;

    return (
      <div
        className={classNames(
          'bank-transfer-form',
          !isVisible && 'bank-transfer-form_hidden',
        )}
      >
        <div className="bank-transfer-form__intro">
          <p className="bank-transfer-form__intro-text">
            {getLocalization('Make a payment through Bank Transfer')}
          </p>
          <LogoPlaid
            className="bank-transfer-form__intro-logo"
            data-testid="Plaid logo"
          />
        </div>
        {plaidToken && bankTransferAmount && (
          <PlaidWrapper
            plaidToken={plaidToken}
            handlePlaidError={setFormError}
            handlePlaidExit={handlePlaidExit}
            handlePlaidSuccess={handlePlaidSuccess}
          />
        )}
      </div>
    );
  },
);

export default BankTransferForm;
