import React from 'react';

import { LabeledValue } from 'src/components/ui-components';

import { GRAVE_PROPERTIES, MAUSOLEUM_PROPERTIES } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { getLot } from 'src/utils';
import type { PropertyListProps } from './property-list.props';

import './property-list.scss';

const PropertyList = ({ lots }: PropertyListProps) => {
  const { getLocalization } = useLocalization();

  return (
    <ul className="property-list">
      <li className="property-list__item property-list__item_header">
        <span className="property-list__header">
          {getLocalization('Property Name')}
        </span>
        <span className="property-list__header">
          {getLocalization('Price')}
        </span>
      </li>

      {lots.map((lot) => {
        const mausoleumName = lot.mausoleum;
        const properties = mausoleumName
          ? MAUSOLEUM_PROPERTIES
          : GRAVE_PROPERTIES;
        const lotProperties = properties.filter(({ field }) => !!lot[field]);

        return (
          <li className="property-list__item" key={lot.number}>
            <span className="property-list__item-lot">
              <span className="property-list__item-name">
                {getLot(lot, getLocalization)}
              </span>
              <span className="property-list__item-properties">
                {mausoleumName && (
                  <LabeledValue
                    className="property-list__mausoleum-name"
                    key="Mausoleum"
                    label={getLocalization('Mausoleum')}
                    value={mausoleumName}
                    valueWeight="medium"
                  />
                )}
                {lotProperties.map(({ label, field }) => (
                  <LabeledValue
                    key={field}
                    label={getLocalization(label)}
                    value={getLocalization(`${lot[field]}`)}
                    valueWeight="medium"
                  />
                ))}
                <LabeledValue
                  label={getLocalization('Rights')}
                  value={getLocalization(lot.right)}
                  valueWeight="medium"
                />
              </span>
            </span>
            <span className="property-list__item-price">
              {lot.price || '-'}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default PropertyList;
