import dayjs from 'dayjs';

import {
  InstalmentStatus,
  PaymentStatus,
  PaymentStatusApi,
} from 'src/constants';

export const getPaymentStatus = (
  status: string,
  paymentDate: string,
  orderDate: string | undefined,
  pageCount: number | undefined,
  index: number | undefined,
) => {
  const dateDiff = dayjs().diff(paymentDate, 'day');

  if (orderDate === paymentDate && dayjs(orderDate) > dayjs()) {
    return InstalmentStatus.CURRENT;
  }

  if (status === PaymentStatus[PaymentStatusApi.PAID]) {
    return status;
  }

  if (status === PaymentStatus[PaymentStatusApi.PENDING]) {
    return InstalmentStatus.PENDING;
  }

  if (status === PaymentStatus[PaymentStatusApi.UNPAID] && dateDiff > 0) {
    return InstalmentStatus.MISSED;
  }

  if (pageCount === 1 && index === 0) {
    return InstalmentStatus.CURRENT;
  }

  return InstalmentStatus.WAITING;
};
