import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from 'src/components/app';

import { LocalizationProvider } from 'src/contexts';

import './index.scss';

ReactDOM.render(
  <Router>
    <LocalizationProvider>
      <App />
    </LocalizationProvider>
  </Router>,
  document.getElementById('root'),
);
