import React, { useEffect } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'src/components/ui-components';

import { useLocalization } from 'src/contexts';
import type { StripeCardProps } from './stripe-card.props';

import './stripe-card.scss';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#3b3b3b',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'uppercase',
      color: '#3b3b3b',
      '::placeholder': {
        color: '#b9b9b9',
      },
    },
    invalid: {
      color: '#d95151',
    },
  },
};

const StripeCard = observer(
  ({
    stripeCardStore,
    isCardCanBeSaved,
    isVisible,
    loadingPayment,
  }: StripeCardProps) => {
    const { getLocalization } = useLocalization();
    const stripe = useStripe();
    const elements = useElements();
    const {
      shouldSaveCard,
      handleCardElementChange,
      handleCvcElementChange,
      handleExpiryElementChange,
      setElements,
      setStripe,
      toggleShouldSaveCard,
    } = stripeCardStore;

    useEffect(() => {
      setElements(elements);
      setStripe(stripe);

      return () => {
        setElements(null);
        setStripe(null);
      };
    }, [elements, stripe, setElements, setStripe]);

    return (
      <div
        className={classNames(
          'stripe-card',
          !isVisible && 'stripe-card_hidden',
        )}
      >
        <div className="stripe-card__inputs">
          <CardNumberElement
            className="stripe-card__input  stripe-card__input_card"
            options={CARD_OPTIONS}
            onChange={handleCardElementChange}
          />
          <CardExpiryElement
            className="stripe-card__input  stripe-card__input_expiry"
            options={CARD_OPTIONS}
            onChange={handleExpiryElementChange}
          />
          <CardCvcElement
            className="stripe-card__input  stripe-card__input_cvc"
            options={CARD_OPTIONS}
            onChange={handleCvcElementChange}
          />
        </div>
        {isCardCanBeSaved && (
          <div className="stripe-card__save-block">
            <Checkbox
              className="stripe-card__checkbox"
              id="paymentFormBillingSaveCheckbox"
              isChecked={shouldSaveCard}
              isDisabled={loadingPayment}
              label={getLocalization(
                'Save this card for recurring instalment payments',
              )}
              onChange={toggleShouldSaveCard}
            />
          </div>
        )}
      </div>
    );
  },
);

export default StripeCard;
