import { getLotLabel } from './get-lot-label';
import { formatAsUSDOptionalCents } from './money';

import {
  LotType,
  LotTypeApi,
  PaymentType,
  PaymentTypeApi,
} from 'src/constants';
import type {
  ICemetery,
  IDataListPair,
  IGetLocalization,
  ILot,
  IPaymentOrderData,
  IPerson,
  IService,
  IServiceItemFormatted,
} from 'src/interfaces';

export const getCemeteryData = (
  cemeteryData: ICemetery,
  getLocalization: IGetLocalization,
): IDataListPair[] => [
  { key: `${getLocalization('Name')}:`, value: cemeteryData.name },
  { key: `${getLocalization('Address')}:`, value: cemeteryData.address },
  { key: `${getLocalization('Phone')}:`, value: cemeteryData.phone },
  { key: `${getLocalization('Email')}:`, value: cemeteryData.email },
];

export const getLotData = (
  lotData: ILot,
  getLocalization: IGetLocalization,
) => {
  const lotLabel = getLocalization(getLotLabel(lotData));

  const data: IDataListPair[] = [
    {
      key: `${lotLabel}:`,
      value: lotData.number,
    },
  ];

  if (
    lotData.type === LotType[LotTypeApi.PLOT] ||
    lotData.type === LotType[LotTypeApi.CREMATION_PLOT]
  ) {
    data.push({
      key: `${getLocalization('Location')}:`,
      value: `${getLocalization('Section')} ${lotData.section}${
        lotData.block ? `, ${getLocalization('Block')} ${lotData.block}` : ''
      }`,
    });
  }

  if (
    lotData.type === LotType[LotTypeApi.CRYPT] ||
    lotData.type === LotType[LotTypeApi.NICHE]
  ) {
    const { bank = '', mausoleum = '' } = lotData;
    data.push({
      key: `${getLocalization('Location')}:`,
      value: `${mausoleum}, ${bank}${
        lotData.tier ? `, ${getLocalization('Tier')} ${lotData.tier}` : ''
      }${lotData.row ? `, ${getLocalization('Row')} ${lotData.row}` : ''}`,
    });
  }

  data.push({ key: `${getLocalization('Rights')}:`, value: lotData.right });

  if (lotData.price) {
    data.push({ key: `${getLocalization('Price')}:`, value: lotData.price });
  }

  return data;
};

export const getPersonalData = (
  deedOwner: IPerson,
  getLocalization: IGetLocalization,
) => [
  { key: `${getLocalization('Name')}:`, value: deedOwner.name },
  { key: `${getLocalization('Address')}:`, value: deedOwner.address },
  { key: `${getLocalization('Date of Birth')}:`, value: deedOwner.dateOfBirth },
  { key: `${getLocalization('Phone Number')}:`, value: deedOwner.phone },
  { key: `${getLocalization('Email')}:`, value: deedOwner.email },
];

export const getPaymentData = (
  paymentData: IPaymentOrderData,
  getLocalization: IGetLocalization,
) => {
  const data: IDataListPair[] = [
    {
      key: `${getLocalization('Total')}:`,
      value: paymentData.total,
      isValueBold: true,
    },
  ];

  if (paymentData.type) {
    data.push({
      key: `${getLocalization('Payment Type')}:`,
      value: paymentData.type,
    });
  }

  if (paymentData.type === PaymentType[PaymentTypeApi.INSTALLMENT]) {
    data.push({
      key: `${getLocalization('Down Payment')}:`,
      value: paymentData.downPayment,
    });

    if (paymentData.paymentFrequency) {
      data.push({
        key: `${getLocalization('Payment Frequency')}:`,
        value: paymentData.paymentFrequency,
      });
    }

    data.push({
      key: `${getLocalization('Regular Payments')}:`,
      value: paymentData.monthlyPayment,
    });
  }

  return data;
};

export const getFormattedServices = (
  services: IService[] = [],
  getLocalization: IGetLocalization,
) =>
  services.reduce((prevVal: IServiceItemFormatted[], currentVal) => {
    const serviceCountArray = new Array(currentVal.count)
      .fill('')
      .map((_serviceItem, idx) => ({
        name: getLocalization(currentVal.service.name),
        price: formatAsUSDOptionalCents(currentVal.service.price),
        isPrepaid: currentVal.prepaid_count
          ? idx < currentVal.prepaid_count
          : false,
      }));

    return [...prevVal, ...serviceCountArray];
  }, []);

export const getServices = (
  services: IServiceItemFormatted[],
  getLocalization: IGetLocalization,
) =>
  services.map(({ price, name }) => [
    {
      key: `${getLocalization('Service name')}:`,
      value: name,
    },
    {
      key: `${getLocalization('Price')}:`,
      value: price,
    },
  ]);

export const getTotal = (
  total: string,
  goodsAndServicesTax: string | undefined,
  perpetualCare: string | undefined,
  discount: string | undefined,
  getLocalization: IGetLocalization,
): IDataListPair[] => [
  {
    key: `${getLocalization('Discount/credit applied')}:`,
    value: discount ? `-${discount}` : '$0',
  },
  ...(goodsAndServicesTax
    ? [
        {
          key: `${getLocalization('GST')}:`,
          value: goodsAndServicesTax,
        },
      ]
    : []),
  ...(perpetualCare
    ? [
        {
          key: `${getLocalization('Perpetual Care')}:`,
          value: perpetualCare,
        },
      ]
    : []),
  { key: `${getLocalization('Total')}:`, value: total },
];
