import React from 'react';
import { observer } from 'mobx-react-lite';

import { Record } from 'src/components/common-components';

import ordersStore from 'src/stores/orders-store';

import { useLocalization } from 'src/contexts';
import {
  formatAsUSDOptionalCents,
  formatDate,
  getLot,
  getOrderAdditionalLabel,
} from 'src/utils';

import './order-info.scss';

const OrderInfo = observer(() => {
  const { getLocalization } = useLocalization();
  const { selectedOrder, selectedOrderRemainingBalance } = ordersStore;

  if (!selectedOrder) {
    return null;
  }

  const { agreementNumber, createdAt, inventoryLot, lots, payment } =
    selectedOrder;

  const additionalInfo = getOrderAdditionalLabel(selectedOrder);

  return (
    <div className="order-info">
      <h1 className="order-info__header">
        {getLocalization('Agreement')} <br className="order-info__br" />
        {agreementNumber}
      </h1>
      <div className="order-info__content">
        <div className="order-info__row order-info__row_property">
          {inventoryLot ? (
            <Record
              name={getLocalization('Property')}
              value={getLot(inventoryLot, getLocalization)}
            />
          ) : (
            !!lots?.length && (
              <Record
                name={getLocalization('Property')}
                value={lots.map((lot) => getLot(lot, getLocalization))}
              />
            )
          )}
          {additionalInfo && (
            <span className="order-info__additional-info">
              {getLocalization(additionalInfo)}
            </span>
          )}
        </div>
        <div className="order-info__row">
          <Record
            name={getLocalization('Date')}
            value={formatDate(createdAt)}
          />
          <Record name={getLocalization('Total cost')} value={payment.total} />
          <Record
            name={getLocalization('Remaining balance')}
            value={formatAsUSDOptionalCents(selectedOrderRemainingBalance)}
          />
        </div>
      </div>
    </div>
  );
});

export default OrderInfo;
