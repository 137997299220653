import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import type { ScrollToTopProps } from './scroll-to-top.props';

const ScrollToTop = ({ children }: ScrollToTopProps) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
