import React from 'react';
import classNames from 'classnames';

import type { TableProps } from './table.props';

import './table.scss';

const Table = ({ className, columns, rows }: TableProps) => (
  <table className={classNames('table', className)}>
    <thead className="table__head">
      <tr className="table__row table__row_head">
        {columns.map((columnHeader, cellIndex) => (
          <th className="table__th" key={cellIndex}>
            {columnHeader}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="table__body">
      {rows.map((cells, rowIndex) => (
        <tr className="table__row" key={rowIndex}>
          {cells.map((cell, cellIndex) => {
            const cellClassName =
              typeof cell === 'object' ? cell.className : '';
            const cellContent = typeof cell === 'object' ? cell.value : cell;
            return (
              <td
                className={classNames('table__cell', cellClassName)}
                key={cellIndex}
              >
                {cellContent}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;
