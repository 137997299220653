import React from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'src/components/ui-components';

import ordersStore from 'src/stores/orders-store';

import { AppRoute, OrderType } from 'src/constants';
import { useLocalization } from 'src/contexts';
import { formatAsUSDOptionalCents } from 'src/utils';
import type { OrderActionsProps } from './order-actions.props';

import './order-actions.scss';

const OrderActions = observer(({ id, type, payment }: OrderActionsProps) => {
  const { getLocalization } = useLocalization();
  const { getIsNextPaymentDisabled, setSelectedOrderId } = ordersStore;

  const handleSignInButtonClick = () => {
    setSelectedOrderId(id);
  };

  const renderActions = () => {
    switch (type) {
      case OrderType.SIGNING:
        return (
          <>
            <div className="order-actions__info">
              <span className="order-actions__title">
                {getLocalization('Your signature is required')}
              </span>
            </div>
            <Button
              className="order-actions__button"
              href={`${AppRoute.SIGNING}/${id}`}
              label={getLocalization('Sign Now')}
              size="oversize"
              tag="link"
              theme="filled"
              onClick={handleSignInButtonClick}
            />
          </>
        );

      case OrderType.ACTIVE:
        return (
          <>
            <div className="order-actions__info">
              <span className="order-actions__title order-actions__title_payment">
                {getLocalization('Next Payment')}:
              </span>
              <span className="order-actions__value">
                {formatAsUSDOptionalCents(payment || 0)}
              </span>
            </div>
            <Button
              className="order-actions__button"
              href={`${AppRoute.PAYMENT}/${id}`}
              isDisabled={getIsNextPaymentDisabled(id)}
              label={getLocalization('Pay Now')}
              size="oversize"
              tag="link"
              theme="filled"
            />
          </>
        );
    }
  };

  return <div className="order-actions__container">{renderActions()}</div>;
});

export default OrderActions;
