import { OrderStatus } from 'src/constants/order-status';

export enum MenuItemStatus {
  SIGNING = 'Signature is required',
  NEXT_PAYMENT = 'Next Payment',
}

export const PaidItemStatus = {
  [OrderStatus.PAID]: 'Paid',
  [OrderStatus.PARTIALLY_PAID]: 'Partially paid',
  [OrderStatus.PENDING_PAYMENT]: 'Pending',
};
